import {ComponentBaseOptions} from '@/components/types';

export enum PaymentComponentLayout {
  ACCORDION = 'accordion',
  TAB = 'tab',
}

export type PaymentComponentUpdateOptions = ComponentBaseOptions & {
  layout?: PaymentComponentLayout;
  defaultItemsToShow?: number;
  itemSortOrder?: string[];
  paymentIntentId?: string;
  amount?: number;
  currencyCode?: string;
};

export type PaymentComponentCreateOptions = PaymentComponentUpdateOptions & {
  _internal?: {
    appUrl?: string;
  };
};
