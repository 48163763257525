import {generateHtmlElement, getElementById, isElement, strToHTML} from '@/hosted_fields/common/dom-utils';
import {
  HTMLIFrameElementWithPaymentAttributes,
  PARAMETERS,
  PaymentComponentCallbacks,
  PaymentComponentCreateOptions,
  PaymentComponentEnvironmentConfiguration,
  PaymentComponentUpdateOptions,
} from '@/components/impl/payment/types';
import {PaymentIntent} from '@/internal/payment-intent/types';
import {ErrorCode} from '@/components/impl/payment/types/error';
import {CB_PAYMENT_COMPONENT_FRAME_ID, CB_PAYMENT_COMPONENT_LOADER_ID} from '@/components/impl/payment/types/constants';
import LightBox from '@/extensions/three_domain_secure/common/lightbox';
import EnvConstants from '@/constants/environment';
import {ComponentType} from '@/components/types/types';
import {
  IncomingEventType,
  PaymentComponentIncomingEventMap,
} from '@/components/impl/payment/messaging/types/incoming-events';
import {OutgoingEventType} from '@/components/impl/payment/messaging/types/outgoing-events';
import EventHandler from '@/components/utils/event-handler';
import LoaderIconSVG from '@/components/impl/payment/templates/loader-icon';

export default class PaymentComponent {
  private readonly callbacks: PaymentComponentCallbacks | undefined;
  private readonly options: PaymentComponentCreateOptions;
  private readonly environmentConfiguration: PaymentComponentEnvironmentConfiguration;

  private eventHandler: EventHandler;
  private container: HTMLElement | null = null;
  private iframe: HTMLIFrameElementWithPaymentAttributes | null = null;

  constructor(options: PaymentComponentCreateOptions, callbacks: PaymentComponentCallbacks | undefined) {
    this.options = options;
    this.callbacks = callbacks;
    this.environmentConfiguration = EnvConstants.COMPONENTS[ComponentType.PAYMENT];
  }

  private getUrl(): string {
    if (this.options && this.options._internal && this.options._internal.appUrl) {
      return this.options._internal.appUrl;
    }
    return this.environmentConfiguration.url;
  }

  public async mount(target: string | HTMLElement): Promise<boolean> {
    const container: HTMLElement | null = getElementById(target);
    if (!container || !isElement(container)) {
      if (this.callbacks) {
        this.callbacks.onError(ErrorCode.NO_CONTAINER_ELEMENT, null);
      }
      throw new Error(ErrorCode.NO_CONTAINER_ELEMENT);
    }
    if (container.children.namedItem(CB_PAYMENT_COMPONENT_FRAME_ID)) {
      if (this.callbacks) {
        this.callbacks.onError(ErrorCode.COMPONENT_ALREADY_PRESENT, null);
      }
      return Promise.resolve(false);
    }

    this.container = container;
    this.toggleLoader(true);
    this.iframe = this.createIframe();
    container.appendChild(this.iframe);

    // It is important to attach listeners after attaching iframe to DOM
    this.eventHandler = new EventHandler({
      iframe: this.iframe,
    });
    this.attachEventListeners();

    return Promise.resolve(true);
  }

  private createIframe() {
    const iframe: HTMLIFrameElementWithPaymentAttributes = generateHtmlElement('iframe', {
      border: '0px',
      overflow: 'hidden',
      display: 'none',
    });
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.id = iframe.name = CB_PAYMENT_COMPONENT_FRAME_ID;
    iframe.src = this.generateUrl().href;
    iframe.allow = 'payment';
    iframe.allowpaymentrequest = true;

    iframe.onload = () => {
      this.toggleLoader(false);
      iframe.style.display = 'block';
      if (this.options) {
        this.update(this.options);
      }
    };
    return iframe;
  }

  public close() {
    if (this.callbacks && this.callbacks.onClose) {
      this.callbacks.onClose();
    }
    if (this.container && this.iframe) {
      this.container.removeChild(this.iframe);
    }
    if (this.container) {
      this.container = null;
    }
    if (this.iframe) {
      this.iframe.remove();
    }
    if (this.eventHandler) {
      this.eventHandler.stop();
    }
  }

  public update(options: PaymentComponentUpdateOptions) {
    this.eventHandler.dispatch(OutgoingEventType.OnUpdate, options);
  }

  public confirmPayment(): Promise<PaymentIntent> {
    return new Promise<PaymentIntent>(async (resolve, reject) => {
      this.eventHandler.dispatch(OutgoingEventType.OnConfirmPayment);
      this.eventHandler
        .waitFor<PaymentIntent>([IncomingEventType.OnPaymentAuthorized, IncomingEventType.OnPaymentRejected])
        .then((eventData) => {
          if (eventData.action === IncomingEventType.OnPaymentAuthorized) {
            return resolve(eventData.payload);
          } else if (eventData.action === IncomingEventType.OnPaymentRejected) {
            return reject(eventData.payload);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  private toggleLoader(showLoader: boolean): void {
    let loader: HTMLElement | null = document.getElementById(CB_PAYMENT_COMPONENT_LOADER_ID);
    if (!loader && showLoader) {
      loader = generateHtmlElement('div', {
        margin: '0',
        padding: '0',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        border: 'none',
        overflow: 'hidden',
        position: 'absolute',
      });
      loader.id = CB_PAYMENT_COMPONENT_LOADER_ID;
      loader.appendChild(
        (() => {
          let circularProgress = generateHtmlElement('div', {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            height: '100%',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            backgroundColor: '#f4f5f9',
            opacity: '.75',
            visibility: 'visible',
          });
          circularProgress.appendChild(<SVGElement>strToHTML(LoaderIconSVG));
          return circularProgress;
        })()
      );
      if (this.container) {
        this.container.appendChild(loader);
      }
    }
    if (loader && !showLoader) {
      if (this.container) {
        this.container.removeChild(loader);
      }
    }
  }

  private generateUrl(): URL {
    const {site, publishableKey} = window.Chargebee.getInstance();

    const url = new URL(this.getUrl());
    url.searchParams.append(PARAMETERS.SITE, site);
    url.searchParams.append(PARAMETERS.PUBLISHABLE_API_KEY, publishableKey);
    url.searchParams.append(PARAMETERS.REFERER, window.location.origin);

    if (this.options) {
      const {paymentIntentId, amount, currencyCode} = this.options;
      if (paymentIntentId) {
        url.searchParams.append(PARAMETERS.PAYMENT_INTENT_ID, paymentIntentId);
      }
      if (amount) {
        url.searchParams.append(PARAMETERS.AMOUNT, String(amount));
      }
      if (currencyCode) {
        url.searchParams.append(PARAMETERS.CURRENCY_CODE, String(currencyCode));
      }
      url.searchParams.append(PARAMETERS.REFERER, window.location.origin);
    }
    return url;
  }

  private onResizeHeight(dimensions: {height: number}) {
    this.iframe.height = `${dimensions.height + 20}px`;
  }

  private async onHandleChallengeUrl(payload: {paymentIntent: PaymentIntent; url: string}) {
    const lightbox = new LightBox(payload.paymentIntent.gateway);
    lightbox.setURL(payload.url);
    lightbox.open();

    await this.eventHandler
      .waitFor([IncomingEventType.OnPaymentAuthorized, IncomingEventType.OnPaymentRejected])
      .finally(() => {
        lightbox.close();
        lightbox.destroy();
      });
  }

  private attachEventListeners() {
    this.eventHandler.addListener<PaymentComponentIncomingEventMap[typeof IncomingEventType.OnChallengeUrl]>(
      IncomingEventType.OnChallengeUrl,
      ({payload}) => this.onHandleChallengeUrl(payload)
    );

    this.eventHandler.addListener<PaymentComponentIncomingEventMap[typeof IncomingEventType.OnResize]>(
      IncomingEventType.OnResize,
      ({payload}) => this.onResizeHeight(payload)
    );
  }
}
