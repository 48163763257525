import {debugMode} from '@/constants/environment';
import {EventHandlerOptions, Listeners, MessagePayload} from './types';
import {DEFAULT_TARGET_ORIGIN, EVENT_HANDLER_DEFAULT_OPTIONS} from './types/constants';

/**
  Iframe Communication controller
  TODO: Make this as a shared utility between chargebee-js & payment-component
*/
export default class EventHandler {
  private iframe: HTMLIFrameElement | undefined;
  private listeners: Listeners;
  private targetOrigin: string = DEFAULT_TARGET_ORIGIN;
  private options: EventHandlerOptions;
  private contentWindow: Window | null | undefined;

  constructor(options: EventHandlerOptions) {
    this.options = Object.assign({...EVENT_HANDLER_DEFAULT_OPTIONS}, options);
    if (!(options.iframe || options.contentWindow)) {
      throw new Error('Invalid configuration for communication controller');
    }

    this.iframe = options.iframe;
    this.contentWindow = this.iframe ? this.iframe.contentWindow : options.contentWindow;
    this.setTargetOrigin();

    this.listeners = {};
    this.bindListener();
  }

  private setTargetOrigin() {
    if (this.iframe) {
      try {
        this.targetOrigin = new URL(this.iframe.src).origin;
      } catch (e) {
        this.targetOrigin = DEFAULT_TARGET_ORIGIN;
      }
    } else if (this.contentWindow) {
      this.targetOrigin = this.options.targetOrigin || DEFAULT_TARGET_ORIGIN;
    }
  }

  private bindListener() {
    window.addEventListener('message', (e) => this.handleMessage(e));
  }

  public stop() {
    window.removeEventListener('message', this.handleMessage);
  }

  private async handleMessage(e: MessageEvent<MessagePayload<any>>) {
    if (!this.isValidEvent(e)) {
      return;
    }

    const callback = this.listeners[e.data.action];
    if (callback && typeof callback === 'function') {
      try {
        await callback(e.data);
      } catch (e) {
        console.error(e);
      }
    }
  }

  public dispatch<T = void>(action: string, payload?: T) {
    const messagePayload: MessagePayload<T> = {
      action,
      payload,
    };
    this.contentWindow && this.contentWindow.postMessage(messagePayload, this.targetOrigin);
  }

  private isValidEvent(e: MessageEvent<MessagePayload>): boolean {
    if (e) {
      const {origin, data} = e;
      if (origin && origin === this.targetOrigin && data && data.action) {
        return true;
      }
    }
    return false;
  }

  public removeListener(eventName: string) {
    if (eventName && this.listeners[eventName]) {
      delete this.listeners[eventName];
    }
  }

  public waitFor<T = void>(messageEvents: Array<string>): Promise<MessagePayload<T>> {
    return new Promise((resolve, reject) => {
      let timeoutId: any;

      const cleanup = () => {
        messageEvents.forEach((eventName) => this.removeListener(eventName));
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };

      // Attach event listeners for all message events from input
      messageEvents.forEach((eventName) => {
        this.addListener<T>(eventName, (data) => {
          // Remove the attached listeners once any of the message is received
          cleanup();
          resolve(data);
        });
      });

      timeoutId = setTimeout(() => {
        reject({
          code: 'message_timeout',
          message: `Message timed out - ${messageEvents.toString()}`,
        });
        cleanup();
      }, this.options.messageTimeout);
    });
  }

  public addListener<T = void>(eventName: string, callback: (e: MessagePayload<T>) => void): void {
    if (eventName && callback && typeof callback === 'function') {
      if (debugMode()) {
        if (this.listeners[eventName]) {
          console.warn('Warning: Overriding listener. Event listener already exists:', eventName);
        }
      }
      this.listeners[eventName] = callback;
    }
  }
}
