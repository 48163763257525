export const CB_PAYMENT_COMPONENT_FRAME_ID = 'cb-payment-component-frame';
export const CB_PAYMENT_COMPONENT_LOADER_ID = 'cb-payment-component-loader';

export const PARAMETERS = {
  SITE: 'site',
  PUBLISHABLE_API_KEY: 'publishable_api_key',
  PAYMENT_INTENT_ID: 'payment_intent_id',
  AMOUNT: 'amount',
  CURRENCY_CODE: 'currency_code',
  REFERER: 'referer',
} as const;
