import {PaymentComponentUpdateOptions} from '@/components/impl/payment/types';

export const OutgoingEventType = {
  OnUpdate: 'on-update',
  OnConfirmPayment: 'on-confirm-payment',
} as const;

export type OutgoingEventType = (typeof OutgoingEventType)[keyof typeof OutgoingEventType];

export interface PaymentComponentOutgoingEventMap {
  [OutgoingEventType.OnUpdate]: PaymentComponentUpdateOptions;
  [OutgoingEventType.OnConfirmPayment]: null;
}
