import ErrorCodes, {CbError} from '@/hosted_fields/common/errors';
import t from '@/hosted_fields/common/locale';
import {
  ComponentBaseOptions,
  ComponentImplCallbacksMap,
  ComponentImplOptionsMap,
  ComponentObjectMap,
  ComponentType,
} from '@/components/types';
import {ComponentConstructors} from '@/components/constructors';

export default class Components {
  private readonly options: ComponentBaseOptions;

  public constructor(options: ComponentBaseOptions) {
    this.options = options;
  }

  public create<K extends keyof ComponentImplOptionsMap>(
    componentType: K,
    options: ComponentBaseOptions & ComponentImplOptionsMap[K],
    callbacks: ComponentImplCallbacksMap[K]
  ): ComponentObjectMap[K] {
    if (!Object.values(ComponentType).includes(componentType)) {
      throw new CbError(t(ErrorCodes.invalidComponent));
    }
    const mergedOptions: ComponentBaseOptions & ComponentImplOptionsMap[K] = {...this.options, ...options};
    return new ComponentConstructors[componentType](mergedOptions, callbacks) as ComponentObjectMap[K];
  }
}
