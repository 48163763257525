import {IncomingEventType} from '../messaging/types/incoming-events';
import PaypalOverlayTemplate from './templates/paypal-overlay';

/*
  Utilities for Paypal Button
*/
export default class PaypalButton {
  private paypalOverlay: HTMLDivElement;

  private showPaypalOverlay() {
    const div = document.createElement('div');
    div.innerHTML = PaypalOverlayTemplate;
    document.body.appendChild(div);
    this.paypalOverlay = div;
  }

  private hidePaypalOverlay() {
    if (this.paypalOverlay) {
      this.paypalOverlay.remove();
      this.paypalOverlay = undefined;
    }
  }

  public getEventHandlers(): {
    [key in IncomingEventType]?: () => void;
  } {
    return {
      [IncomingEventType.ShowPaypalOverlay]: () => {
        this.showPaypalOverlay();
      },
      [IncomingEventType.HidePaypalOverlay]: () => {
        this.hidePaypalOverlay();
      },
    };
  }
}
