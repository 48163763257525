export const IncomingEventType = {
  PaymentButtonStatus: 'payment-button-status',
  ShowPaypalOverlay: 'show-paypal-overlay',
  HidePaypalOverlay: 'hide-paypal-overlay',
} as const;

export type IncomingEventType = (typeof IncomingEventType)[keyof typeof IncomingEventType];

export type PaymentButtonIncomingEventMap = {
  [IncomingEventType.PaymentButtonStatus]: {
    rendered: boolean;
  };
  [IncomingEventType.HidePaypalOverlay]: void;
  [IncomingEventType.ShowPaypalOverlay]: void;
};
