export interface ErrorObjectMap {
  [ErrorCode.NO_CONTAINER_ELEMENT]: null;
  [ErrorCode.COMPONENT_ALREADY_PRESENT]: null;
}

export const ErrorCode = {
  NO_CONTAINER_ELEMENT: 'NO_CONTAINER_ELEMENT',
  COMPONENT_ALREADY_PRESENT: 'COMPONENT_ALREADY_PRESENT',
} as const;

export type ErrorCode = (typeof ErrorCode)[keyof typeof ErrorCode];
