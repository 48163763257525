export type PaymentButtonCreateOptions = {
  //
};

export type PaymentButtonEnvironmentConfiguration = {
  url: string;
};

export enum CssStyle {
  DISPLAY = 'display',
  HEIGHT = 'height',
  WIDTH = 'width',
  MIN_HEIGHT = 'minHeight',
}

export type ButtonCSSProperties = {
  [key in CssStyle]?: string;
};

export type PaymentButtonCallbacks = {};

export * from './constants';
