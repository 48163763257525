import PaymentComponent from '@/components/impl/payment';
import PaymentButton from '@/components/impl/payment-button';

import {
  ComponentBaseOptions,
  ComponentObjectMap,
  ComponentType,
  ComponentImplCallbacksMap,
  ComponentImplOptionsMap,
} from '@/components/types';

export const ComponentConstructors: {
  [K in keyof ComponentObjectMap]: new (
    options: ComponentBaseOptions & ComponentImplOptionsMap[K],
    callbacks: ComponentImplCallbacksMap[K]
  ) => ComponentObjectMap[K];
} = {
  [ComponentType.PAYMENT]: PaymentComponent as new () => PaymentComponent,
  [ComponentType.PAYMENT_BUTTON]: PaymentButton as new () => PaymentButton,
};
