import {
  generateHtmlElement,
  getComputedProperty,
  getElementById,
  isElement,
  strToHTML,
} from '@/hosted_fields/common/dom-utils';
import {ErrorCode} from '../payment/types/error';
import EventHandler from '@/components/utils/event-handler';
import {HTMLIFrameElementWithPaymentAttributes} from '../payment/types';
import {CB_PAYMENT_BUTTON_COMPONENT_FRAME, PARAMETERS} from './types/constants';
import {ButtonCSSProperties, CssStyle, PaymentButtonEnvironmentConfiguration} from './types';
import EnvConstants from '@/constants/environment';
import {ComponentType} from '@/components/types';
import {IncomingEventType, PaymentButtonIncomingEventMap} from './messaging/types/incoming-events';
import PaypalButton from './paypal';

export default class PaymentButton {
  private iframe: HTMLIFrameElement;
  private targetEl: HTMLElement;
  private eventHandler: EventHandler;
  private merchantButtonCssProperties: ButtonCSSProperties;
  private readonly environmentConfiguration: PaymentButtonEnvironmentConfiguration;

  constructor() {
    this.environmentConfiguration = EnvConstants.COMPONENTS[ComponentType.PAYMENT_BUTTON];
    this.merchantButtonCssProperties = {};
  }

  private generateUrl(): URL {
    const {site, publishableKey} = window.Chargebee.getInstance();

    const url = new URL(this.environmentConfiguration.url);
    url.searchParams.append(PARAMETERS.SITE, site);
    url.searchParams.append(PARAMETERS.PUBLISHABLE_API_KEY, publishableKey);
    url.searchParams.append(PARAMETERS.REFERER, window.location.origin);

    return url;
  }

  private createIframe() {
    const iframe: HTMLIFrameElementWithPaymentAttributes = generateHtmlElement('iframe', {
      border: '0px',
      overflow: 'hidden',
      display: 'none',
      height: '0px',
      width: '100%',
    });
    iframe.id = iframe.name = CB_PAYMENT_BUTTON_COMPONENT_FRAME;
    iframe.src = this.generateUrl().href;
    iframe.allow = 'payment';
    iframe.allowpaymentrequest = true;
    iframe.name = 'cb-payment-button-component';

    return iframe;
  }

  private applyStyles(el: HTMLElement) {
    [CssStyle.HEIGHT, CssStyle.WIDTH, CssStyle.MIN_HEIGHT].forEach((style) => {
      el.style[style] = this.merchantButtonCssProperties[style];
    });
  }

  private showPaymentButton() {
    if (this.iframe) {
      this.iframe.style.display = 'block';
      this.applyStyles(this.iframe);
    }
    this.targetEl.style.display = 'none';
  }

  private hidePaymentButton() {
    if (this.iframe) {
      this.iframe.style.display = 'none';
    }
    this.targetEl.style.display = this.merchantButtonCssProperties.display;
  }

  private attachListeners() {
    this.eventHandler.addListener<PaymentButtonIncomingEventMap[typeof IncomingEventType.PaymentButtonStatus]>(
      IncomingEventType.PaymentButtonStatus,
      ({payload}) => {
        if (payload.rendered) {
          this.showPaymentButton();
        } else {
          this.hidePaymentButton();
        }
      }
    );

    // Attach payment button listeners
    const paypalButton = new PaypalButton();
    const handlers = paypalButton.getEventHandlers();
    Object.keys(handlers).forEach((eventName) => {
      this.eventHandler.addListener(eventName, handlers[eventName]);
    });
  }

  private setButtonCssProperties(el: HTMLElement) {
    const computedHeight = getComputedProperty(el, 'height');
    const computedWidth = getComputedProperty(el, 'width');

    this.merchantButtonCssProperties = {
      display: el.style.display,
      height: el.style.height || computedHeight || '45px',
      minHeight: '45px',
      width: el.style.width || computedWidth || '100%',
    };
  }

  async mount(target: string | HTMLElement): Promise<boolean> {
    const container: HTMLElement | null = getElementById(target);
    if (!container || !isElement(container)) {
      throw new Error(ErrorCode.NO_CONTAINER_ELEMENT);
    }

    this.targetEl = container;
    this.setButtonCssProperties(this.targetEl);
    this.iframe = this.createIframe();
    container.parentNode.insertBefore(this.iframe, container.nextSibling);

    // It is important to attach listeners after attaching iframe to DOM
    this.eventHandler = new EventHandler({
      iframe: this.iframe,
    });
    this.attachListeners();
    return Promise.resolve(true);
  }

  close(): void {
    this.hidePaymentButton();
    this.eventHandler && this.eventHandler.stop();
    this.iframe && this.iframe.remove();
  }
}
