import {PaymentIntent} from '@/internal/payment-intent/types';

export const IncomingEventType = {
  OnResize: 'on-resize',
  OnChallengeUrl: 'on-challenge-url',
  OnPaymentAuthorized: 'on-payment-authorized',
  OnPaymentRejected: 'on-payment-rejected',
} as const;

export type IncomingEventType = (typeof IncomingEventType)[keyof typeof IncomingEventType];

export interface PaymentComponentIncomingEventMap {
  [IncomingEventType.OnResize]: {
    height: number;
  };
  [IncomingEventType.OnChallengeUrl]: {
    url: string;
    paymentIntent: PaymentIntent;
  };
  [IncomingEventType.OnPaymentAuthorized]: PaymentIntent;
  [IncomingEventType.OnPaymentRejected]: PaymentIntent;
}
